.block.rules {
    height: 100vh;

    .background {
        background: url('../imgs/reglas-back.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .holder {
        margin: 0 auto;
        max-width: 1178px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
    }

    .title {
        color: #15151E;
        font-family: 'LeagueSpartan-Bold';
        font-size: 48px;
        position: relative;

        &:after {
            background: #E10600;
            bottom: 0;
            content: '';
            display: block;
            height: 3px;
            left: -33px;
            position: absolute;
            width: 67px;
        }
    }

    .desc {
        color: #38383F;
        font-size: 18px;
        margin-top: 25px;
        max-width: 49%;
        width: 100%;
        
        b {
            font-family: Halcyon-Bold;
            font-weight: normal;
        }
    }

    .content {
        margin-top: 40px;
    }

    .flex-content {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    .text-wrapper {
        height: 512px;
        max-width: 49%;
        padding-bottom: 146px;
        position: relative;
        width: 100%;

        &:after {
            background-image: linear-gradient(180deg, rgba(255,255,255,0.00) 1%, #FFFFFF 100%);
            bottom: -5px;
            content: '';
            display: block;
            height: 146px;
            left: 0;
            position: absolute;
            width: 100%;
        }

        .text {
            height: 512px;
            overflow: scroll;
            padding-bottom: 146px;
            padding-right: 23px;
        }
    }

    .image-wrapper {
        max-width: 40.5%;
        width: 100%;
    }
    
    .back {
        align-items: center;
        bottom: 80px;
        display: flex;
        left: 70px;
        position: fixed;
        text-decoration: none;

        .icon {
            max-width: 25px;
            width: 100%;
        }

        .text {
            color: #15151E;
            font-size: 16px;
            line-height: 23px;
            margin-left: 15px;
            text-transform: uppercase;
        }
    }
}

@media (max-height: 950px) {

    .block.rules {

        .title {
            font-size: 28px;
        }

        .desc {
            font-size: 14px;
            max-width: 100%;
        }

        .content {
            margin-top: 0;
        }

        .text-wrapper {
            height: 200px;
            padding-bottom: 100px;

            .text {
                height: 200px;
                padding-bottom: 100px;
            }

            &:after {
                height: 100px;
            }
        }
    }
}

@media (max-width: 767px) {

    .block.rules {
        height: auto;
        padding: 120px 25px 80px;

        .holder {
            top: unset;
            transform: none;
        }

        .title {
            font-size: 28px;
            line-height: 42px;

            &:after {
                bottom: -2px;
                height: 2px;
                left: -25px;
                width: 50px;
            }
        }

        .desc {
            font-size: 14px;
            margin-top: 22px;
        }

        .content {
            margin-top: 40px;
        }

        .text-wrapper {
            height: auto;
            max-width: 100%;
            padding-bottom: unset;

            &:after {
                content: none;
            }

            .text {
                font-size: 12px;
                height: auto;
                overflow: unset;
                padding-bottom: unset;
            }
        }

        .back {
            bottom: unset;
            justify-content: center;
            left: unset;
            margin-top: 50px;
            position: relative;

            .icon {
                max-width: 20px;
            }

            .text {
                font-size: 14px;
                line-height: 15px;
                margin-left: 10px;
            }
        }
    }
}