.navigation {
    background: #FFFFFF;
    left: 0;
    padding: 28px 70px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;

    &:after {
        background: #383840;
        bottom: 0;
        content: '';
        display: block;
        height: 1px;
        left: 50%;
        opacity: 0.14;
        position: absolute;
        transform: translateX(-50%);
        width: calc(100% - 140px);
    }

    .content {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    .logo {
        max-width: 130px;
        width: 100%;
    }

    .list {
        align-items: center;
        display: flex;
    }

    .option {
        cursor: pointer;
        margin-right: 40px;
        text-decoration: none;
        text-align: center;

        &:last-child {
            margin-right: unset;
        }
    }

    button.option {
        background: transparent;
        border: none;
        margin: 0;
        outline: none;
        padding: 0;
    }

    span {
        display: block;
    }

    .icon {
        margin: 0 auto;
        max-width: 20px;
        width: 100%;
    }

    .text {
        color: #38383F;
        font-size: 12px;
        margin-top: 5px;
    }
}

@media (max-width: 767px) {

    .navigation {
        padding: 18px;

        &:after {
            width: calc(100% - 40px);
        }

        .logo {
            margin: 0 auto;
            max-width: 101px;
        }

        /* Navigation - Toggle Button */
        .toggle-btn {
            background: #F5F5F5;
            border: none;
            border-radius: 50%;
            height: 30px;
            outline: none;
            padding: 10px;
            position: absolute;
            right: 25px;
            top: 50%;
            transform: translateY(-50%);
            transition: all 550ms ease-in-out;
            width: 30px;
        
            .bar {
                background: #E10600;
                display: block;
                height: 1px;
                left: 5px;
                position: absolute;
                transition: all 550ms ease-in-out;
                width: 20px;
        
                &:nth-child(1) {
                top: 9px;
                transform: rotate(0) translateY(0);
                }
        
                &:nth-child(2) {
                opacity: 1;
                top: 50%;
                transform: translateY(-50%);
                }
        
                &:nth-child(3) {
                bottom: 9px;
                transform: rotate(0) translateY(0);
                }
            }
        
            &.expanded {
                background: #E10600;
        
                .bar {
                    background: #F5F5F5;
        
                    &:nth-child(1) {
                        top: 50%;
                        transform: rotate(45deg) translateY(-50%);
                    }
            
                    &:nth-child(2) {
                        opacity: 0;
                    }
            
                    &:nth-child(3) {
                        bottom: 50%;
                        transform: rotate(-45deg) translateY(50%);
                    }
                }
            }
        }

        .list-wrapper {
            height: calc(100% - 70px);
            left: 0;
            max-width: unset;
            opacity: 0;
            position: fixed;
            top: 70px;
            transform: translateX(100%);
            transition: all 550ms ease-in-out;
            width: 100%;

            &:after {
                background-image: linear-gradient(180deg, #FFFFFF 1%, #F3F3F3 100%);
                content: '';
                display: block;
                height: 100%;
                left: 0;
                opacity: 0.94;
                position: absolute;
                top: 0;
                width: 100%;
            }
            
            &.list-expanded {
                opacity: 0.95;
                transform: translateX(0);
            }
        }

        .list {
            align-items: flex-start;
            flex-direction: column;
            margin: 0 auto;
            max-width: 262px;
            position: relative;
            margin-top: 108px;
            z-index: 1;
        }

        .option {
            align-items: center;
            display: flex;
            margin-bottom: 60px;
            margin-right: unset;
            text-transform: uppercase;

            &:last-child {
                margin-bottom: unset;
            }
        }

        .icon {
            max-width: 30px;
            width: 30px;
        }

        .text {
            margin-left: 10px;
        }
    }
}