.pilots-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;

    .pilot-container {
        box-shadow: 2px 2px 5px rgba(0, 0, 0, .26);
        color: #38383F;
        margin-bottom: 20px;
        max-width: 19.3%;
        position: relative;
        width: 100%;
    }

    .pilot-wrapper {
        cursor: pointer;
        height: 100%;
        overflow: hidden;
        padding: 30px 10px 25px;
        position: relative;
        transition: all 350ms ease-in-out;

        &:before, &:after {
            content: '';
            display: block;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            transition: all 350ms ease-in-out;
            width: 100%;
            z-index: -1;
        }

        &:before {
            background-image: linear-gradient(134deg, #FFFFFF 0%, #F0EFF8 100%);
            opacity: 1;
        }

        &:after {
            background-image: linear-gradient(134deg, #15151E 0%, #565666 100%);
            opacity: 0;
        }

        &:hover, &.selected {
            color: #ffffff;

            &:before {
                opacity: 0;
            }

            &:after {
                opacity: 1;
            }
        }

        &#already-selected {
            opacity: 0.4;
            pointer-events: none;
        }
    }

    .already-selected {
        background: #ffffff;
        height: 100%;
        left: 0;
        opacity: 0.7;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1111;
    }

    .info {
        line-height: 13px;
        padding-left: 5px;
        position: relative;
        text-transform: uppercase;

        &:before {
            content: '';
            display: block;
            height: 22px;
            left: 0;
            position: absolute;
            top: 0;
            width: 2px;
        }

        &#Redbull {

            &:before {
                background: #0601EF;
            }
        }

        &#Mercedes {

            &:before {
                background: #00D0BB;
            }
        }

        &#Ferrari {

            &:before {
                background: #E10600;
            }
        }

        &#McLaren {

            &:before {
                background: #FF9800;
            }
        }

        &#Alpine {

            &:before {
                background: #0090FF;
            }
        }

        &#Alpha-Tauri {

            &:before {
                background: #2C4461;
            }
        }

        &#Aston-Martin {

            &:before {
                background: #026F62;
            }
        }

        &#Williams {

            &:before {
                background: #005AFF;
            }
        }

        &#Alfa-Romeo {

            &:before {
                background: #8F0000;
            }
        }

        &#Haas {

            &:before {
                background: #F5F5F5;
            }
        }
    }

    .name {
        font-family: 'Halcyon-Regular';
        font-size: 10px;
    }

    .lastname {
        font-family: 'Halcyon-Bold';
        font-size: 12px;
    }

    .flag {
        margin-top: 30px;
        max-width: 18px;
        width: 100%;
    }

    .pilot-image {
        bottom: 0;
        max-width: 66%;
        position: absolute;
        right: -20%;
    }
}

@media (max-width: 767px) {

    .pilots-list {
        
        .pilot-container {
            max-width: 45%;
        }
    }
}