.block.home {
    color: #F3F3F3;
    height: 100vh;
    position: relative;
    width: 100vw;

.background {
    background: url('../imgs/brw-home.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    &:after {
    background-image: linear-gradient(180deg, rgba(0,0,0,0.40) 0%, #000000 100%);
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    }
}

.content {
    left: 50%;
    max-width: 670px;
    padding: 0 15px;
    position: relative;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
}

.icon {
    margin: 0 auto;
    max-width: 256px;
    width: 100%;
}

.title {
    font-family: 'LeagueSpartan-Bold';
    font-size: 42px;
    line-height: 48px;
    margin-top: 50px;
}

.desc {
    font-family: 'LeagueSpartan-Bold';
    font-size: 26px;
    line-height: 48px;
    margin-top: 10px;
}

.text {
    font-family: 'Halcyon-Regular';
    font-size: 16px;
    line-height: 23px;
    margin-top: 10px;

    b {
    display: block;
    font-family: 'Halcyon-Bold';
    font-size: 18px;
    font-weight: normal;
    }
}

.buttons-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 50px;
    max-width: 295px;
    width: 100%;

    a {
    align-items: center;
    color: #FFFFFF;
    display: flex;
    height: 40px;
    font-family: 'Halcyon-Regular';
    font-size: 14px;
    justify-content: center;
    line-height: 18px;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 550ms ease-in-out;
    width: 130px;
    }
}

a.register {
    background: transparent;
    border: 2px solid #E10600;
    border-radius: 4px;

    &:hover {
    background: #E10600;
    }
}

a.login {
    background: #E10600;
    border-radius: 4px;

    &:hover {
    background: #740300;
    }
}
}

@media (max-height: 600px) {

.block.home {
    
    .icon {
    max-width: 180px;
    }

    .title {
    font-size: 32px;
    line-height: 38px;
    margin-top: 25px;
    }

    .desc {
    font-size: 24px;
    line-height: 46px;
    margin-top: 5px;
    }

    .text {
    font-size: 14px;
    line-height: 22px;
    margin-top: 5px;

    b {
        font-size: 16px;
    }
    }
}
}

@media (max-width: 768px) {

.block.home {

    .content {
    max-width: 364px;
    }

    .icon {
    max-width: 160px;
    }

    .title {
    font-size: 32px;
    margin-top: 30px;
    }

    .desc {
    font-size: 24px;
    line-height: 36px;
    }

    .text {
    font-size: 14px;
    line-height: 18px;

    b {
        font-size: 16px;
    }
    }

    .buttons-wrapper {
    margin-top: 30px;
    max-width: 270px;

    a {
        font-size: 12px;
        height: 35px;
        line-height: 12px;
        width: 120px;
    }
    }
}
}