.block.profile {
    height: 100vh;
    position: relative;

    .background {
        background: url('../imgs/mi-perfil-back.jpeg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;

        &:after {
            background: #FFFFFF;
            content: '';
            display: block;
            height: 100%;
            left: 0;
            opacity: .9;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }

    .holder {
        margin: 0 auto;
        max-width: 1178px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
    }

    .title {
        color: #15151E;
        font-family: 'LeagueSpartan-Bold';
        font-size: 48px;
        position: relative;

        &:after {
            background: #E10600;
            bottom: 0;
            content: '';
            display: block;
            height: 3px;
            left: -33px;
            position: absolute;
            width: 67px;
        }
    }

    .content {
        padding-top: 60px;
    }

    .flex-content {
        display: flex;
        justify-content: space-between;
    }

    .text-wrapper, .competitions {
        max-width: 48.5%;
        width: 100%;
    }

    .info-wrapper {
        max-width: 48.5%;
        padding-top: 25px;
        width: 100%;
    }

    .info {
        color: #15151E;
        font-family: 'LeagueSpartan-Bold';
        font-size: 18px;
    }

    .creditos {
        color: #E10600;
        font-size: 58px;
        line-height: 60px;

        span {
            color: #15151E;
            font-size: 28px;
        }
    }

    .info-label {
        color: #38383F;
        font-size: 16px;
        margin-top: 5px;
    }

    .t-header-gplk {
        background: #E10600;
        color: #FFFFFF;
        font-family: 'LeagueSpartan-Bold';
        font-size: 14px;
        line-height: 22px;
        padding: 9px;
        text-align: center;
        text-transform: uppercase;
    }

    .concurso {
        color: #15151E;
        font-family: 'Halcyon-Regular';
        font-size: 14px;
        padding-top: 5px;
        text-align: center;
        text-transform: uppercase;

        &:nth-child(even) {

            .concurso-name {
                background: #E9E9E9;
            }
        }

        &:nth-child(odd) {

            .concurso-name {
                background: #F5F5F5;
            }
        }
    }

    .concurso-name {
        padding: 5px;
    }
    
    .back {
        align-items: center;
        bottom: 80px;
        display: flex;
        left: 70px;
        position: fixed;
        text-decoration: none;

        .icon {
            max-width: 25px;
            width: 100%;
        }

        .text {
            color: #15151E;
            font-size: 16px;
            line-height: 23px;
            margin-left: 15px;
            text-transform: uppercase;
        }
    }
}

@media (max-width: 1000px) {

    .block.profile {
        padding: 0 30px;

        .text-wrapper {

            .flex-content {
                flex-direction: column;
            }
        }

        .info-wrapper {
            max-width: 100%;
        }
    }
}

@media (max-height: 700px) {

    .block.profile {

        .title {
            font-size: 32px;
        }

        .content {
            padding-top: 0;
        }

        .info {
            font-size: 12px;
        }

        .info-label {
            font-size: 10px;
        }
    }
}

@media (max-width: 767px) {

    .block.profile {
        height: auto;
        min-height: 100vh;
        padding: 120px 25px 80px;

        .holder {
            max-width: 600px;
            top: unset;
            transform: unset;
        }

        .title {
            font-size: 28px;
            line-height: 42px;

            &:after {
                bottom: -2px;
                height: 2px;
                left: -25px;
                width: 50px;
            }
        }

        .content {
            padding-top: 22px;
        }

        .flex-content {
            flex-direction: column;
        }

        .text-wrapper, .competitions {
            max-width: 100%;
        }

        .info-wrapper {
            padding-top: 20px;
        }

        .info {
            font-size: 20px;
            line-height: 30px;
        }

        .info-label {
            font-size: 14px;
            line-height: 20px;
            margin-top: 5px;
        }

        .competitions {
            margin-top: 50px;
        }

        .back {
            bottom: unset;
            justify-content: center;
            left: unset;
            margin-top: 50px;
            position: relative;

            .icon {
                max-width: 20px;
            }

            .text {
                font-size: 14px;
                line-height: 15px;
                margin-left: 10px;
            }
        }
    }
}