.block.race-result {
    padding: 132px 0 225px;
    position: relative;

    .holder {
        margin: 0 auto;
        max-width: 1330px;
        width: 100%;
    }

    .header {
        font-family: 'LeagueSpartan-Bold';
        text-transform: uppercase;
    }

    .title {
        color: #E10600;
        font-size: 28px;
    }

    .desc {
        color: #15151E;
        font-size: 20px;
    }

    .content {
        margin-top: 40px;
    }

    .gplk-table-header {
        background: #E10600;
        color: #FFFFFF;
        font-family: 'LeagueSpartan-Bold';
        font-size: 14px;
        padding: 9px;
        text-align: center;
        text-transform: uppercase;
    }

    .flex-content {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .user-content {
        max-width: 16.6%;
        width: 100%;
    }

    .strategy-content {
        max-width: 22.3%;
        width: 100%;
    }

    .draw-content {
        max-width: 22.3%;
        width: 100%;
    }

    .question-content {
        max-width: 12.5%;
        width: 100%;
    }

    .gplk-table-indicator {
        background: #38383F;
        color: #FFFFFF;
        font-family: 'Halcyon-Regular';
        font-size: 14px;
        padding: 6px 5px 10px;
        text-align: center;
    }

    .gplk-row {
        color: #15151E;
        font-family: 'Halcyon-Regular';
        font-size: 14px;
        margin-top: 5px;
        min-height: 60px;
        text-align: center;

        .points, .info-value {
            padding: 9px 0 6px;
        }

        &#active {
            color: #E10600;
            font-weight: bold;
        }

        &.main {
            font-size: 10px;
            margin-top: unset;

            .points, .info-value {
                background: #C1C1C3 !important;
                padding: 4px 0 3px;
            }
        }

        &:nth-child(odd) {

            .points, .info-value {
                background: #E9E9E9;
            }
        }

        &:nth-child(even) {

            .points, .info-value {
                background: #F5F5F5;
            }
        }
    }

    .points {
        width: 28px;
    }

    .info-value {
        max-width: 85%;
        width: 100%;

        &.small-info {
            max-width: 38.3%;
        }

        &.question {
            max-width: 80%;
        }
    }
    
    .back {
        align-items: center;
        bottom: 80px;
        display: flex;
        left: 70px;
        position: absolute;
        text-decoration: none;

        .icon {
            max-width: 25px;
            width: 100%;
        }

        .text {
            color: #15151E;
            font-size: 16px;
            line-height: 23px;
            margin-left: 15px;
            text-transform: uppercase;
        }
    }

    button {
        align-items: center;
        background: #E10600;
        border: 1px solid #E10600;
        border-radius: 4px;
        color: #FFFFFF;
        cursor: pointer;
        display: flex;
        font-family: 'Halcyon-Regular';
        font-size: 12px;
        height: 35px;
        justify-content: center;
        line-height: 12px;
        margin-top: 20px;
        text-decoration: none;
        text-transform: uppercase;
        transition: all 550ms ease-in-out;
        width: 147px;

        &:hover {
            background: transparent;
            color: #E10600;
        }
    }
}

@media (max-width: 767px) {

    .block.race-result {

        .content {
            overflow-x: auto;
        }

        .gplk-table {
            width: 1200px;
        } 
    }
}