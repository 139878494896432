.status-message {
    background: transparent;
    border: 1px solid rgba(56, 56, 63, .5);
    border-radius: 2px;
    height: 100%;
    left: 50%;
    max-width: 102%;
    padding: 0 15px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 3;

    .sending, .success, .error {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        width: 100%;

        .icon {
            margin: 0 auto 15px;
            max-width: 52px;
            width: 100%;
        }
    }

    .error, .success .message, .sending {
        color: #000000;
        font-family: 'Halcyon-Bold';
        font-size: 14px;
        text-align: center;
        text-transform: uppercase;

        strong {
            color: #E10600;
        }
    }

    .icon {
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: contain !important;
        margin: 0 auto;
        height: 60px;
        width: 60px;

        &.icon-sending {
            background: url('../imgs/sending.svg');
        }

        &.icon-success {
            background: url('../imgs/sent.svg');
        }
    }
}