.footer-home {
    bottom: 0;
    color: #F3F3F3;
    font-family: 'Halcyon-Regular';
    font-size: 10px;
    left: 0;
    letter-spacing: 0;
    padding: 20px 70px;
    position: fixed;
    width: 100%;

    ul.opts {
        display: flex;
    }

    li {
        margin-right: 25px;
    }

    a {
        color: inherit;
        display: block;
        text-decoration: none;
        text-transform: uppercase;

        &:hover {
        font-weight: bold;
        }
    }
}

@media (max-width: 767px) {

    .footer-home {
        font-size: 8px;
        padding: 15px 40px;

        ul.opts {
            flex-wrap: wrap;
        }

        li {
            margin-right: 15px;

            &:first-child {
                font-size: 9px;
                margin-bottom: 10px;
                width: 100%;
            }
        }
    }
} 