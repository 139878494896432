.block.credits {
    padding: 186px 20px;
    position: relative;

    .background {
        background: url('../imgs/creditos-back.jpeg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;

        &:after {
            background: #FFFFFF;
            content: '';
            display: block;
            height: 100%;
            left: 0;
            opacity: .9;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }

    .holder {
        margin: 0 auto;
        max-width: 1178px;
        position: relative;
        width: 100%;
    }

    .title {
        color: #15151E;
        font-family: 'LeagueSpartan-Bold';
        font-size: 48px;
        position: relative;

        &:after {
            background: #E10600;
            bottom: 0;
            content: '';
            display: block;
            height: 3px;
            left: -33px;
            position: absolute;
            width: 67px;
        }
    }

    .desc {
        color: #38383F;
        font-size: 18px;
        margin-top: 25px;
        
        b {
            font-family: Halcyon-Bold;
            font-weight: normal;
        }
    }

    .content {
        margin-top: 50px;
    }

    .flex-content {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    .left-side {
        max-width: 32.3%;
        width: 100%;
    }

    .image-wrapper {
        border-radius: 8px;
        margin-top: 50px;
        overflow: hidden;
    }

    .form-wrapper {
        max-width: 55.1%;
        width: 100%;

        .flex-content {
            align-items: flex-end;
        }
    }

    .text-one {
        color: #E10600;
        font-family: Halcyon-Bold;
        font-size: 18px;
        text-align: center;
    }

    .text-two {
        color: #38383F;
        font-family: Halcyon-Regular;
        font-size: 16px;
        margin-top: 15px;
    }

    form {
        position: relative;
    }

    .wrapper-content-form {
        opacity: 1;

        &.partial-hide {
            opacity: 0;
        }
    }

    .inputs-wrapper {

        &:nth-child(2) {
            padding-right: 58px;
            position: relative;

            &:after {
                bottom: -22px;
                color: #15151E;
                content: 'CR';
                font-family: 'LeagueSpartan-Bold';
                font-size: 28px;
                line-height: 60px;
                position: absolute;
                right: 0;
            }
        }
    }

    .input-container {
        display: flex;
        flex-direction: column-reverse;
    }

    .mt {
        margin-top: 23px;
    }

    label {
        color: #38383F;
        display: block;
        font-family: 'Halcyon-Regular';
        font-size: 14px;
        font-weight: normal;
        margin: 0;
        margin-bottom: 6px;
        padding: 0;
    }

    .normal-input {
        background: transparent;
        border: 1px solid;
        border-color: rgba(21, 21, 32, .5);
        border-radius: 2px;
        color: #15151F;
        font-size: 14px;
        outline: none;
        padding: 11.5px 8px;
        width: 100%;

        &:focus {
            border-color: #151520;

            +label {
                font-family: 'Halcyon-SemiBold';
            }
        }

        &.invalid {
            border-color: #E10600;

            +label {
                color: #E10600;
            }
        }
    }

    .credits-input {
        background: transparent;
        border: 1px solid #E10600;
        border-radius: 2px;
        color: #15151E;
        font-family: 'LeagueSpartan-Bold';
        font-size: 28px;
        line-height: 60px;
        outline: none;
        padding: 11px 11px 2px;
        text-align: center;

        +label {
            color: #38383F;
            font-family: 'Halcyon-SemiBold';
            font-size: 14px;
            margin-bottom: 23px;
        }
    } 

    input[type=submit] {
        align-items: center;
        background: #E10600;
        border: 1px solid #E10600;
        border-radius: 4px;
        color: #FFFFFF;
        display: flex;
        font-family: 'Halcyon-Regular';
        font-size: 14px;
        height: 40px;
        justify-content: center;
        line-height: 18px;
        margin-left: auto;
        margin-top: 35px;
        text-transform: uppercase;
        transition: all 550ms ease-in-out;
        width: 130px;

        &:hover {
            background: transparent;
            color: #E10600;
        }
    }
    
    .back {
        align-items: center;
        bottom: 80px;
        display: flex;
        left: 70px;
        position: fixed;
        text-decoration: none;

        .icon {
            max-width: 25px;
            width: 100%;
        }

        .text {
            color: #15151E;
            font-size: 16px;
            line-height: 23px;
            margin-left: 15px;
            text-transform: uppercase;
        }
    }

    .flex-input {
        display: flex;
        justify-content: space-between;

        .input-container {
            max-width: 48%;
            width: 100%;
        }
    }

    .concursos-content {
        padding: 40px 0 60px;
    }

    .heading-wrapper, .option {
        display: flex;
        justify-content: space-between;

        div {
            max-width: 24%;
            text-align: center;
            width: 100%;

            &:first-child {
                text-align: left;
            }
        }
    }

    .heading-wrapper {
        font-size: 12px;
    }

    .option {
        cursor: pointer;
        font-size: 16px;
        margin-top: 20px;

        div {
            pointer-events: none;
        }

        &.active {
            font-weight: bold;

            .main-opt {
                
                &:after {
                    opacity: 1 !important;
                }
            }
        }
    }

    .main-opt {
        padding-left: 36px;
        position: relative;

        &:before {
            border: 1px solid #E10600;
            border-radius: 4px;
            content: '';
            display: block;
            height: 20px;
            left: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 20px;
        }

        &:after {
            background: url('../imgs/palomita.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            content: '';
            display: block;
            height: 10px;
            left: 4px;
            opacity: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 12px;
        }
    }
}

@media (max-height: 775px) {

    .block.credits {

        .left-side {
            max-width: 20%;
        }

        .title {
            font-size: 32px;
        }

        .content {
            margin-top: 10px;
        }
    }
}

@media (max-width: 767px) {

    .block.credits {
        height: auto;
        padding: 120px 25px 80px;

        .holder {
            top: unset;
            transform: unset;
        }

        .title {
            font-size: 28px;
            line-height: 42px;

            &:after {
                bottom: -2px;
                height: 2px;
                left: -25px;
                width: 50px;
            }
        }

        .desc {
            font-size: 14px;
            margin-top: 22px;
        }

        .content {
            margin-top: unset;
            padding-top: 20px;
        }

        .flex-content {
            flex-direction: column;
        }

        .left-side {
            margin: 0 auto;
            max-width: 600px;
        }

        .text-one {
            font-size: 16px;
            margin-top: 30px;
            text-align: left;
        }

        .text-two {
            font-size: 13px;
            margin-top: 10px;
        }

        .form-wrapper {
            margin: 0 auto;
            max-width: 600px;
        }

        .inputs-wrapper {
            max-width: 100% !important;

            &:nth-child(2) {
                padding-right: unset;

                &:after {
                    bottom: 10px;
                    font-size: 22px;
                    line-height: 40px;
                    right: 10px;
                }
            }
        }

        .input-container {
            margin-top: 20px;
        }

        label {
            font-size: 12px;
            margin-bottom: 2px;
        }

        .normal-input {
            padding: 7px 8px 8px;
        }

        .credits-input {
            font-size: 28px;
            line-height: 32px;
            padding: 26px 10px 17px;

            +label {
                font-size: 14px;
                margin-bottom: 2px;
            }
        }

        input[type=submit] {
            font-size: 12px;
            height: 35px;
            line-height: 12px;
            margin: 0 auto;
            margin-top: 30px;
            width: 120px;
        }

        .back {
            bottom: unset;
            justify-content: center;
            left: unset;
            margin-top: 55px;
            position: relative;

            .icon {
                max-width: 20px;
            }

            .text {
                font-size: 14px;
                line-height: 15px;
                margin-left: 10px;
            }
        }

        .flex-input {
            display: block;

            .input-container {
                max-width: 100%;
            }
        }

        .heading-wrapper {
            font-size: 10px;
        }

        .option {
            font-size: 14px;
        }

        .main-opt {
            padding-left: 18px;
    
            &:before {
                height: 14px;
                width: 14px;
            }
    
            &:after {
                height: 10px;
                left: 2px;
                width: 10px;
            }
        }
    }
}