.block.strategy {
    padding: 132px 0 115px;
    position: relative;

    .holder {
        margin: 0 auto;
        max-width: 1178px;
        width: 100%;
    }

    .header {
        font-family: 'LeagueSpartan-Bold';
        text-transform: uppercase;
    }

    .title {
        color: #E10600;
        font-size: 28px;
    }

    .desc {
        color: #15151E;
        font-size: 20px;
    }

    .content {
        margin-top: 52px;
    }

    .flex-content {
        display: flex;
        justify-content: space-between;
    }

    .select-strategy {
        max-width: 29%;
        width: 100%;
    }

    .lists-wrapper {
        max-width: 68%;
        width: 100%;

        h1 {
            position: relative;
            text-align: center;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .strategy-header {
        background: #E10600;
        border-radius: 5px 5px 0 0;
        color: #FFFFFF;
        font-family: 'LeagueSpartan-Bold';
        font-size: 18px;
        padding: 15px 5px 14px;
        text-align: center;
    }

    .opt {
        background: #FFFFFF;
        color: #15151E;
        cursor: pointer;
        padding: 41px 35px;
        position: relative;
        transition: all 350ms ease-in-out;

        .opt-desc, .arrow-active {
            opacity: 0;
        }

        .opt-checked {
            max-width: 25px;
            opacity: 0;
            position: absolute;
            right: 35px;
            top: 50%;
            transform: translateY(-50%);
            transition: all 550ms ease-in-out;
            width: 100%;
        }

        &:after {
            background: #E10600;
            bottom: 0;
            content: '';
            display: block;
            height: 1px;
            left: 0;
            position: absolute;
            width: 100%;
        }

        &:last-child {

            &:after {
                content: none;
            }
        }

        &:hover, &.active {
            background: #F3F3F3;
        }

        &.active {
            padding: 19px 35px 61px;

            .opt-desc, .arrow-active {
                opacity: 1;
            }
        }

        &.opt-selected {

            .opt-checked {
                opacity: 1;
            }
        }
    }

    .opt-title, .opt-desc, .arrow-active {
        pointer-events: none;
    }

    .opt-title {
        color: #15151E;
        font-family: 'LeagueSpartan-Bold';
        font-size: 22px;
        line-height: 34px;
        text-transform: uppercase;
    }

    .opt-desc {
        color: #38383F;
        font-family: 'Halcyon-Regular';
        font-size: 14px;
        left: 35px;
        max-width: 187px;
        position: absolute;
        transition: all 550ms ease-in-out;
        top: 55px;
        width: 100%;
    }

    .arrow-active {
        left: 10px;
        max-width: 9px;
        position: absolute;
        transition: all 350ms ease-in-out;
        top: 26px;
    }
    
    .back {
        align-items: center;
        bottom: 80px;
        display: flex;
        left: 70px;
        position: absolute;
        text-decoration: none;

        .icon {
            max-width: 25px;
            width: 100%;
        }

        .text {
            color: #15151E;
            font-size: 16px;
            line-height: 23px;
            margin-left: 15px;
            text-transform: uppercase;
        }
    }

    .submit-btn {
        align-items: center;
        background: #E10600;
        border: 1px solid #E10600;
        border-radius: 4px;
        color: #FFFFFF;
        cursor: pointer;
        display: flex;
        font-family: 'Halcyon-Regular';
        font-size: 14px;
        height: 35px;
        justify-content: center;
        line-height: 13px;
        margin: 0 auto;
        margin-top: 40px;
        text-decoration: none;
        text-transform: uppercase;
        transition: all 550ms ease-in-out;
        width: 150px;

        &:hover {
            color: #E10600;
            background: transparent;
        }
    }
}

@media (max-width: 767px) {

    .block.strategy {
        padding: 120px 0 156px;

        .holder {
            max-width: 364px;
        }

        .title {
            font-size: 26px;
        }

        .desc {
            font-size: 18px;
        }

        .content {
            margin-top: 30px;
        }

        .strategy-header {
            font-size: 14px;
            padding: 9px 18px;
            text-align: left;
            text-transform: uppercase;
        }

        .flex-content {
            flex-direction: column;
        }

        .select-strategy, .lists-wrapper {
            max-width: 100%;
        }

        .select-strategy {
            padding-bottom: 66px;
            position: relative;
        }

        .lists-wrapper {
            margin-top: 15px;
        }

        .opts-wrapper {
            display: flex;
        }

        .opt {
            background: #F3F3F3;
            max-width: 33.333%;
            padding: 11px 0 !important;
            text-align: center;
            width: 100%;

            &:after {
                height: 30px;
                left: unset;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 1px;
            }

            .opt-checked {
                left: 5px;
                max-width: 15px;
                right: unset;
            }
        }

        .opt-title {
            font-size: 10px;
        }

        .opt-desc {
            bottom: 15px;
            font-size: 12px;
            left: 50%;
            max-width: 100%;
            padding: 0 5px;
            text-align: center;
            top: unset;
            transform: translateX(-50%);
        }

        .arrow-active {
            left: 10px;
            max-width: 5px;
            top: 50%;
            transform: translateY(-50%);
        }

        .back {
            bottom: 80px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}