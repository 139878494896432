.block.contact {
    height: 100vh;
    position: relative;

    &:before {
        background: url('../imgs/brw.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        display: block;
        height: 55px;
        left: 50%;
        position: absolute;
        top: 38px;
        transform: translateX(-50%);
        width: 160px;
        z-index: 1;
    }

    .background {
        background: url('../imgs/contacto-back.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .holder {
        margin: 0 auto;
        max-width: 1178px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
    }

    .title {
        color: #15151E;
        font-family: 'LeagueSpartan-Bold';
        font-size: 48px;
        position: relative;

        &:after {
            background: #E10600;
            bottom: 0;
            content: '';
            display: block;
            height: 3px;
            left: -33px;
            position: absolute;
            width: 67px;
        }
    }

    .desc {
        color: #38383F;
        font-size: 18px;
        margin-top: 25px;
        max-width: 32.3%;
        width: 100%;
        
        b {
            font-family: Halcyon-Bold;
            font-weight: normal;
        }
    }

    .content {
        margin-top: 50px;
    }

    .flex-content {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    .image-wrapper {
        border-radius: 8px;        
        max-width: 32.3%;
        overflow: hidden;
        width: 100%;
    }

    .form-wrapper {
        max-width: 61.5%;
        width: 100%;

        .flex-content {
            align-items: flex-end;
        }
    }

    form {
        position: relative;
    }

    .wrapper-content-form {
        opacity: 1;

        &.partial-hide {
            opacity: 0;
        }
    }

    .input-container-small, .inputs-wrapper {
        max-width: 42%;
        width: 100%;
    }

    .inputs-wrapper {

        .input-container {
            margin-top: 30px;

            &:first-child {
                margin-top: unset;
            }
        }
    }

    .input-container-large, .input-container-small, .input-container {
        display: flex;
        flex-direction: column-reverse;
    }

    .input-container-large {
        max-width: 53.3%;
        width: 100%;
    }

    .textarea-container {
        margin-top: 30px;
    }

    label {
        color: #38383F;
        display: block;
        font-family: 'Halcyon-Regular';
        font-size: 14px;
        font-weight: normal;
        margin: 0;
        margin-bottom: 6px;
        padding: 0;
    }

    input[type=text], textarea {
        background: transparent;
        border: 1px solid;
        border-color: rgba(21, 21, 32, .5);
        border-radius: 2px;
        color: #15151F;
        font-size: 14px;
        outline: none;
        padding: 11.5px 8px;
        width: 100%;

        &:focus {
            border-color: #151520;

            +label {
                font-family: 'Halcyon-SemiBold';
            }
        }

        &.invalid {
            border-color: #E10600;

            +label {
                color: #E10600;
            }
        }
    }

    textarea {
        display: block;
        height: 146px;
        resize: none;
    }

    input[type=submit] {
        align-items: center;
        background: #E10600;
        border: 1px solid #E10600;
        border-radius: 4px;
        color: #FFFFFF;
        display: flex;
        font-family: 'Halcyon-Regular';
        font-size: 14px;
        height: 40px;
        justify-content: center;
        line-height: 18px;
        margin-left: auto;
        margin-top: 35px;
        text-transform: uppercase;
        transition: all 550ms ease-in-out;
        width: 130px;

        &:hover {
            background: transparent;
            color: #E10600;
        }
    }
    
    .back {
        align-items: center;
        bottom: 80px;
        display: flex;
        left: 70px;
        position: fixed;
        text-decoration: none;

        .icon {
            max-width: 25px;
            width: 100%;
        }

        .text {
            color: #15151E;
            font-size: 16px;
            line-height: 23px;
            margin-left: 15px;
            text-transform: uppercase;
        }
    }
}

@media (max-height: 800px) {

    .block.contact {

        &:before {
            height: 35px;
            top: 20px;
            width: 100px;
        }

        .title {
            font-size: 32px;
        }

        .desc {
            max-width: 80%;
        }

        .content {
            margin-top: 10px;
        }
    }
}

@media (max-width: 767px) {

    .block.contact {
        height: auto;
        padding: 120px 25px 80px;

        &:before {
            height: 35px;
            top: 18px;
            width: 101px;
        }

        &:after {
            background: #383840;
            content: '';
            display: block;
            height: 1px;
            left: 50%;
            opacity: 0.14;
            position: absolute;
            top: 70px;
            transform: translateX(-50%);
            width: calc(100% - 40px);
        }

        .holder {
            top: unset;
            transform: unset;
        }

        .title {
            font-size: 28px;
            line-height: 42px;

            &:after {
                bottom: -2px;
                height: 2px;
                left: -25px;
                width: 50px;
            }
        }

        .desc {
            font-size: 14px;
            margin-top: 22px;
            max-width: 100%;
        }

        .content {
            margin-top: unset;
            padding-top: 20px;
        }

        .flex-content {
            flex-direction: column;
        }

        .form-wrapper {
            max-width: 100%;
        }

        .input-container-small, .input-container-large, .inputs-wrapper {
            max-width: 100%;
        }

        .input-container-small, .input-container-large, .input-container {
            margin-top: 20px !important;
        }

        label {
            font-size: 12px;
            margin-bottom: 2px;
        }

        input[type=text], textarea {
            padding: 7px 8px 8px;
        }

        input[type=submit] {
            font-size: 12px;
            height: 35px;
            line-height: 12px;
            margin-top: 30px;
            width: 120px;
        }

        .back {
            bottom: unset;
            justify-content: center;
            left: unset;
            margin-top: 55px;
            position: relative;

            .icon {
                max-width: 20px;
            }

            .text {
                font-size: 14px;
                line-height: 15px;
                margin-left: 10px;
            }
        }
    }
}