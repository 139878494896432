.pl-modal {
    align-items: center;
    background: #F3F3F3;
    border-radius: 10px;
    display: flex;
    height: 436px;
    justify-content: center;
    margin: 0 auto;
    max-width: 632px;
    overflow: hidden;
    position: relative;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;

    .close-modal {
        cursor: pointer;
        height: 22px;
        position: absolute;
        right: 15px;
        top: 15px;
        width: 22px;
        z-index: 1;

        img {
            left: 0;
            position: absolute;
            top: 0;
            transition: all 550ms ease-in-out;
        }

        .icon-normal {
            opacity: 1;
        }

        .icon-hover {
            opacity: 0;
        }

        &:hover {

            .icon-hover {
                opacity: 1;
            }

            .icon-normal {
                opacity: 0;
            }
        }
    }

    .holder {
        margin: 0 auto;
        max-width: 410px;
        position: relative;
        width: 100%;
    }

    .title {
        color: #E10600;
        font-family: 'LeagueSpartan-Bold';
        font-size: 28px;
    }

    .desc {
        color: #38383F;
        font-family: 'LeagueSpartan-Bold';
        font-size: 20px;
        text-transform: uppercase;
    }

    .indications {
        color: #15151E;
        font-family: 'Halcyon-Bold';
        font-size: 16px;
        margin: 20px 0 15px;
    }

    input[type=text] {
        background: transparent;
        border: 1px solid #38383F;
        border-radius: 2px;
        display: block;
        height: 45px;
        padding: 10px;
        outline: none;
        width: 100%;
    }

    label {
        color: #38383F;
        font-family: 'Halcyon-Regular';
        font-size: 10px;
        margin: 0;
        margin-top: 5px;
    }

    input[type=submit] {
        align-items: center;
        background: #E10600;
        border: none;
        border-radius: 4px;
        color: #FFFFFF;
        display: flex;
        font-family: 'Halcyon-Regular';
        font-size: 14px;
        height: 35px;
        line-height: 18px;
        margin: 0 auto;
        margin-top: 25px;
        justify-content: center;
        text-align: center;
        text-transform: uppercase;
        width: 150px;
    }

    .welcome-message {
        color: #15151E;
        font-family: 'Halcyon-Bold';
        font-size: 16px;
        margin-top: 20px;
    }

    .negative-message {
        color: #15151E;
        font-family: 'Halcyon-Bold';
        font-size: 18px;
    }

    .gplk-btn {
        align-items: center;
        background: #E10600;
        border: none;
        border-radius: 4px;
        color: #FFFFFF;
        display: flex;
        font-family: 'Halcyon-Regular';
        font-size: 14px;
        height: 40px;
        line-height: 18px;
        margin: 0 auto;
        margin-top: 25px;
        justify-content: center;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        width: 180px;
    }

    .confirm {
        align-items: center;
        background: #E10600;
        border: none;
        border-radius: 4px;
        color: #FFFFFF;
        display: flex;
        font-family: 'Halcyon-Regular';
        font-size: 14px;
        height: 40px;
        line-height: 18px;
        margin: 0 auto;
        margin-top: 25px;
        justify-content: center;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        width: 150px;
    }

    .cancel {
        color: #38383F;
        cursor: pointer;
        display: block;
        font-family: 'Halcyon-Regular';
        font-size: 12px;
        line-height: 18px;
        margin-top: 15px;
        text-transform: uppercase;
    }

    .advertising {
        color: #15151E;
        font-family: 'Halcyon-Bold';
        font-size: 18px;

        span {
            color: #E10600;
            font-style: italic;
        }
    }
}

@media (max-width: 767px) {

    .pl-modal {
        width: 95%;
    }
}