.block.strategy-results {
    padding: 100px 15px;
    margin: 0 auto;
    max-width: 663px;
    width: 100%;

    .table {
        margin: 0 auto;
        margin-top: 100px;
        max-width: 633px;
        text-align: center;
        width: 100%;
    }

    .flex-content {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        text-align: center;
    }

    .position-field {
        max-width: 122px;
        width: 100%;
    }

    .pilot-field {
        max-width: 257px;
        width: 100%;
    }

    .points-field {
        max-width: 242px;
        width: 100%;
    }

    input {
        display: block;
        outline: none;
        width: 100%;
    }

    .back {
        display: block;
        margin-top: 50px;
    }

    .custom-select {
        position: relative;
        width: fit-content;
    }

    .indication {
        align-items: center;
        border: 1px solid #E10600;
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        height: 35px;
        padding: 0 10px;
        position: relative;
        width: 222px;
    }

    .options-wrapper {
        background: #ffffff;
        border: 1px solid #E10600;
        border-radius: 4px;
        height: 140px;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 1;

        &:after {
            background-image: linear-gradient(180deg, rgba(255,255,255,0.00) 1%, #ffffff 80%);
            bottom: 0;
            content: '';
            display: block;
            height: 35px;
            left: 0;
            position: absolute;
            width: 100%;
        }
    }

    .list-opts {
        height: 140px;
        overflow-y: scroll;
        padding-bottom: 35px;
        padding-top: 15px;

        li {
            cursor: pointer;
            padding: 5px 0;
            position: relative;

            &:after {
                background: #E10600;
                bottom: 0;
                content: '';
                display: block;
                left: 50%;
                height: 1px;
                opacity: 0.5;
                position: absolute;
                transform: translateX(-50%);
                width: 100px;
            }

            &:last-child {

                &:after {
                    content: none;
                }
            }
        }
    }

    .save-btn {
        cursor: pointer;
        margin-top: 50px;
        text-align: center;
    }
}