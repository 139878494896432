.block.cover-races {
    margin-left: auto;
    max-width: 75%;
    padding: 78px 0 30px;
    position: relative;
    width: 100%;

    .holder {
        margin: 0 auto;
        max-width: 967px;
        position: relative;
        width: 100%;
    }

    .flex-content {
        display: flex;
    }

    .title {
        color: #E10600;
        font-family: 'LeagueSpartan-Bold';
        font-size: 28px;
    }

    .desc {
        color: #ffffff;
        font-family: 'LeagueSpartan-Bold';
        font-size: 20px;
        text-transform: uppercase;
    }

    .credits {
        color: #FFFFFF;
        font-family: 'Halcyon-Bold';
        font-size: 35px;
        line-height: 40px;
        margin-top: 5px;

        span {
            font-size: 25px;
        }
    }

    .bolsa {
        color: #F3F3F3;
        font-family: 'Halcyon-SemiBold';
        font-size: 18px;
        margin-top: 10px;
        text-transform: uppercase;
    }

    .right-side {
        margin-left: 68px;
    }

    .prizes-title {
        color: #F3F3F3;
        font-family: 'Halcyon-Regular';
        font-size: 16px;
        text-align: center;
        text-transform: uppercase;
    }

    li {
        display: flex;

        span {
            display: block;
        }
    }

    .position {
        color: #F3F3F3;
        font-family: 'Halcyon-Regular';
        font-size: 16px;
        text-align: right;
        line-height: 30px;
        width: 65px;
    }

    .percentage {
        color: #F3F3F3;
        font-family: 'Halcyon-Bold';
        font-size: 16px;
        line-height: 30px;
        margin-left: 20px;
    }

    .icon {
        margin-left: 10px;
        max-width: 15px;
        width: 100%;

        img {
            width: 100%;
        }
    }
}

@media (max-width: 767px) {

    .block.cover-races {
        padding: 34px 25px 20px;
        max-width: 100%;

        .flex-content {
            flex-direction: column;
        }

        .title {
            font-size: 26px;
        }

        .desc {
            font-size: 18px;
        }

        .credits {
            font-size: 30px;
            line-height: 14px;
            margin-top: 15px;

            span {
                font-size: 20px;
            }
        }

        .bolsa {
            font-size: 16px;
            margin-top: 20px;
        }
    }
}