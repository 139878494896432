.footer-register {
    bottom: 0;
    color: #15151E;
    font-family: 'Halcyon-Regular';
    font-size: 10px;
    left: 0;
    letter-spacing: 0;
    padding: 20px 70px;
    position: fixed;
    width: 100%;

    &:after {
        background: #383840;
        content: '';
        display: block;
        height: 1px;
        left: 50%;
        opacity: 0.14;
        position: absolute;
        top: 0;
        transform: translateX(-50%);
        width: calc(100% - 140px);
    }

    ul.opts {
        display: flex;
    }

    li {
        margin-right: 25px;
    }

    a {
        color: inherit;
        display: block;
        text-decoration: none;
        text-transform: uppercase;

        &:hover {
            font-weight: bold;
        }
    }
}

@media (max-height: 800px) {

    .footer-register {
        position: absolute;
    }
}

@media (max-width: 767px) {

    .footer-register {
        font-size: 8px;
        padding: 15px 40px;
        position: absolute;

        &:after {
            width: calc(100% - 40px);
        }

        ul.opts {
            flex-wrap: wrap;
        }

        li {
            margin-right: 15px;

            &:first-child {
                font-size: 9px;
                margin-bottom: 10px;
                width: 100%;
            }
        }
    }
} 