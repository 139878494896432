.block.activate-race {
    padding: 200px 15px;
    margin: 0 auto;
    max-width: 663px;
    width: 100%;

    h1, h2 {
        color: #FFFFFF;
        display: block;
        font-family: 'LeagueSpartan-Bold';
        font-size: 14px;
        letter-spacing: 0;
        margin: 0;
        padding: 9px;
        text-align: center;
        text-transform: uppercase;
    }

    h1 {
        background: #E10600;
    }

    h2 {
        background: #38383F;
    }

    .back {
        display: block;
        margin-top: 50px;
    }

    label, input, select {
        display: block;
    }

    form {
        margin-top: 50px;
    }

    .input-container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 35px;
    }

    label {
        color: #15151E;
        font-family: 'Halcyon-Regular';
        font-size: 18px;
        font-weight: normal;
        letter-spacing: 0;
        line-height: 18px;
        max-width: 187px;
        width: 100%;
    }

    input[type=text], input[type=date], input[type=time], input[type=datetime-local], select {
        background: #ffffff;
        border: 1px solid #E10600;
        border-radius: 4px;
        max-width: 410px;
        outline: none;
        padding: 10px;
        width: 100%;
        z-index: 1;

        &:after {
            background-image: linear-gradient(180deg, rgba(255,255,255,0.00) 1%, #ffffff 80%);
            bottom: 0;
            content: '';
            display: block;
            height: 35px;
            left: 0;
            position: absolute;
            width: 100%;
        }
    }

    input[type=submit] {
        background: #E10600;
        border: 1px solid #E10600;
        border-radius: 8px;
        color: #ffffff;
        margin-left: auto;
        padding: 5px 15px;
        transition: all 550ms ease-in-out;
        width: fit-content;

        &:hover {
            background: #ffffff;
            color: #E10600;
        }
    }

    option {

        &:first-child {
            display: none;
        }
    }
}

@media (max-width: 768px) {

    .block.activate-race {

        .input-container {
            display: block;
        }

        label, input[type=text], input[type=date], input[type=datetime-local], input[type=time], select {
            max-width: 100%;
        }

        input[type=text], input[type=date], input[type=datetime-local], input[type=time], select {
            margin-top: 15px;
        }
    }
}