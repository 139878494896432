@font-face {
    font-family: 'Halcyon-Regular';
    font-display: swap;
    src: url('../fonts/Halcyon-Regular.eot');
    src: url('../fonts/Halcyon-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Halcyon-Regular.svg#Halcyon-Regular') format('svg'),
        url('../fonts/Halcyon-Regular.otf') format('otf'),
        url('../fonts/Halcyon-Regular.ttf') format('truetype'),
        url('../fonts/Halcyon-Regular.woff') format('woff'),
        url('../fonts/Halcyon-Regular.woff2') format('woff2');
}
@font-face {
    font-family: 'Halcyon-Bold';
    font-display: swap;
    src: url('../fonts/Halcyon-Bold.eot');
    src: url('../fonts/Halcyon-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Halcyon-Bold.svg#Halcyon-Bold') format('svg'),
        url('../fonts/Halcyon-Bold.otf') format('otf'),
        url('../fonts/Halcyon-Bold.ttf') format('truetype'),
        url('../fonts/Halcyon-Bold.woff') format('woff'),
        url('../fonts/Halcyon-Bold.woff2') format('woff2');
}
@font-face {
    font-family: 'Halcyon-SemiBold';
    font-display: swap;
    src: url('../fonts/Halcyon-SemiBold.eot');
    src: url('../fonts/Halcyon-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Halcyon-SemiBold.svg#Halcyon-SemiBold') format('svg'),
        url('../fonts/Halcyon-SemiBold.otf') format('otf'),
        url('../fonts/Halcyon-SemiBold.ttf') format('truetype'),
        url('../fonts/Halcyon-SemiBold.woff') format('woff'),
        url('../fonts/Halcyon-SemiBold.woff2') format('woff2');
}
@font-face {
    font-family: 'LeagueSpartan-Bold';
    font-display: swap;
    src: url('../fonts/leaguespartan-bold.eot');
    src: url('../fonts/leaguespartan-bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/LeagueSpartan-Bold.svg#LeagueSpartan-Bold') format('svg'),
        url('../fonts/LeagueSpartan-Bold.otf') format('otf'),
        url('../fonts/LeagueSpartan-Bold.ttf') format('truetype'),
        url('../fonts/LeagueSpartan-Bold.woff') format('woff'),
        url('../fonts/leaguespartan-bold.woff2') format('woff2');
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -ms-font-smoothing: antialiased;
    -o-font-smoothing: antialiased;
    font-smoothing: antialiased;
    font-family: 'Halcyon-Regular';
    letter-spacing: 0;

    -webkit-text-rendering: optimizeLegibility;
    -moz-text-rendering: optimizeLegibility;
    -ms-text-rendering: optimizeLegibility;
    -o-text-rendering: optimizeLegibility;
    text-rendering: optimizeLegibility;

    &.no-scroll {
        overflow: hidden;
    }
}

.outer-wrapper {
    overflow: hidden;
    position: relative;
}

img {
    display: block;
    max-width: 100%;
    width: 100%;
}

ul, li {
    list-style: none;
    margin: 0;
    padding: 0;
}

.alerta {
    border-radius: 1rem;
    font-size: 2.2rem;
    font-weight: 600;
    left: auto;
    padding: 1rem 3rem;
    position: fixed;
    right: 10px;
    text-align: center;
    text-transform: uppercase;
    top: 150px;
    width: auto;
    z-index: 1111111;
}

.alerta-ok {
    background-color: rgb(109, 211, 109);
    color: rgb(2, 103, 2);
}

.alerta-error {
    background-color: rgb(230, 78, 78);
    color: #ffffff;
}

@media (max-width: 767px) {

    .alerta {
        left: 0;
        top: 0;
        width: 100%;
    }
}