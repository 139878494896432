.block.sideBar-races {
    height: calc(100vh - 100px);
    left: 0;
    padding-top: 30px;
    position: fixed;
    top: 100px;
    width: 25%;
    z-index: 2;

    .holder {
        position: relative;
    }

    .button {
        align-items: center;
        background: transparent;
        border: 1px solid #E10600;
        border-radius: 4px;
        color: #FFFFFF;
        display: flex;
        font-family: 'Halcyon-Regular';
        font-size: 14px;
        height: 40px;
        justify-content: center;
        line-height: 18px;
        margin: 30px auto 0;
        text-decoration: none;
        transition: all 550ms ease-in-out;
        width: 188px;

        &:hover {
            background: #E10600;
        }

        &.inactive {
            border: 1px solid #f9f9f9;
            color: #f9f9f9;
            opacity: 0.4;
            pointer-events: none;
        }
    }
    
    .back {
        align-items: center;
        bottom: 80px;
        display: flex;
        left: 70px;
        position: fixed;
        text-decoration: none;

        .icon {
            max-width: 25px;
            width: 100%;
        }

        .text {
            color: #FFFFFF;
            font-size: 16px;
            line-height: 23px;
            margin-left: 15px;
            text-transform: uppercase;
        }
    }
}

@media (max-width: 767px) {

    .block.sideBar-races {
        bottom: 0;
        height: auto;
        padding-top: unset;
        top: unset;
        width: 100%;

        .content {
            display: flex;
            position: relative;
        }

        .button {
            border: none;
            height: 55px;
            line-height: 12px;
            margin-top: unset;
            width: 50%;
        }
    }
}