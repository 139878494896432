.lista-constructores {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;

    .item-container {
        box-shadow: 2px 2px 5px rgba(0, 0, 0, .20);
        color: #15151E;
        margin-bottom: 20px;
        max-width: 19.3%;
        position: relative;
        width: 100%;
    }

    .item {
        cursor: pointer;
        height: 100%;
        overflow: hidden;
        padding: 15px 5px 12px;
        position: relative;
        transition: all 350ms ease-in-out;
        width: 100%;

        &:before, &:after {
            content: '';
            display: block;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            transition: all 350ms ease-in-out;
            width: 100%;
            z-index: -1;
        }

        &:before {
            background-image: linear-gradient(134deg, #FFFFFF 0%, #F0EFF8 100%);
            opacity: 1;
        }

        &:after {
            background-image: linear-gradient(134deg, #15151E 0%, #565666 100%);
            opacity: 0;
        }

        &:hover, &.selected {
            color: #ffffff;

            &:before {
                opacity: 0;
            }

            &:after {
                opacity: 1;
            }
        }

        &#already-selected {
            opacity: 0.4;
            pointer-events: none;
        }
    }

    .already-selected {
        background: #ffffff;
        height: 100%;
        left: 0;
        opacity: 0.7;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1111;
    }

    .logo {
        margin: 0 auto;
        max-width: 53px;
        width: 100%;
    }

    .constructor-name {
        font-family: 'LeagueSpartan-Bold';
        font-size: 12px;
        line-height: 13px;
        margin-top: 14px;
        text-align: center;
    }
}

@media (max-width: 767px) {

    .lista-constructores {

        .item-container {
            max-width: 45%;
        }
    }
}