.questions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &.comodins {
        margin-top: 30px;
    }

    .item {
        margin-bottom: 50px;
        max-width: 48%;
        width: 100%;
    }

    .item-title {
        color: #15151E;
        font-family: 'Halcyon-Bold';
        font-size: 22px;
    }

    .custom-select {
        position: relative;
        width: fit-content;
    }

    .question {
        color: #38383F;
        font-family: 'Halcyon-Regular';
        font-size: 18px;
        line-height: 27px;
        margin-top: 10px;
    }

    .indication {
        align-items: center;
        border: 1px solid #E10600;
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        height: 35px;
        margin-top: 30px;
        padding: 0 10px;
        position: relative;
        width: 222px;
    }

    span.text {
        color: #E10600;
        display: block;
        font-family: 'Halcyon-Regular';
        font-size: 14px;
        line-height: 11px;
    }

    .arrow {
        display: block;
        max-width: 7px;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
    }

    .options-wrapper {
        background: #ffffff;
        border: 1px solid #E10600;
        border-radius: 4px;
        height: 140px;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 1;

        &:after {
            background-image: linear-gradient(180deg, rgba(255,255,255,0.00) 1%, #ffffff 80%);
            bottom: 0;
            content: '';
            display: block;
            height: 35px;
            left: 0;
            position: absolute;
            width: 100%;
        }
    }

    .list-opts {
        height: 140px;
        overflow-y: scroll;
        padding-bottom: 35px;
        padding-top: 15px;

        li {
            cursor: pointer;
            padding: 5px 0;
            position: relative;

            &:after {
                background: #E10600;
                bottom: 0;
                content: '';
                display: block;
                left: 50%;
                height: 1px;
                opacity: 0.5;
                position: absolute;
                transform: translateX(-50%);
                width: 100px;
            }

            &:last-child {

                &:after {
                    content: none;
                }
            }
        }
    }
}

@media (max-width: 767px) {

    .questions {

        .item {
            margin-bottom: 15px;
            max-width: 100%;
            padding-top: 35px;
        }

        .indication {
            width: 100%;
        }

        .custom-select {
            width: 170px;
        }
    }
}