.block.races-list {
    margin-left: auto;
    max-width: 75%;
    position: relative;
    width: 100%;

    .holder {
        margin: 0 auto;
        max-width: 1032px;
        position: relative;
        width: 100%;
    }

    .content {
        margin-top: 10px;
    }

    .race {
        display: flex;
        justify-content: space-between;
        padding: 40px 0;
        position: relative;

        &:after {
            background: #C1C1C3;
            bottom: 0;
            content: '';
            display: block;
            height: 1px;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
            width: 100%;
        }

        &:last-child {

            &:after {
                content: none;
            }
        }
    }

    .width-fix {
        width: 80px;
    }

    .ml {
        margin-left: 35px;
    }

    .race-date {
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        border-radius: 10px;
        color: #FFFFFF;
        max-width: 55.4%;
        overflow: hidden;
        padding: 30px 35px;
        position: relative;
        width: 100%;

        &:before {
            background-image: linear-gradient(-134deg, rgba(21,21,30,0.50) 0%, #15151E 100%);
            content: '';
            display: block;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }

    .flex-content {
        display: flex;
        position: relative;
    }

    .index {
        display: inline-block;
        font-family: 'LeagueSpartan-Bold';
        font-size: 28px;
    }

    .flag {
        display: inline-block;
        margin-left: 23px;
        max-width: 40px;
        width: 100%;
    }

    .date {
        font-family: 'Halcyon-Regular';
        font-size: 18px;
        letter-spacing: 1px;
    }

    .schedule {
        align-items: center;
        border: 1px solid #C1C1C3;
        border-radius: 4px;
        color: #C1C1C3;
        cursor: pointer;
        display: flex;
        font-family: 'Halcyon-Regular';
        font-size: 10px;
        height: 25px;
        justify-content: center;
        line-height: 8px;
        margin-top: 13px;
        pointer-events: none;
        text-transform: uppercase;
        transition: all 550ms ease-in-out;
        width: 95px;

        &#active {
            border: 1px solid #E10600;
            color: #FFFFFF;
            pointer-events: initial;
        }

        &:hover {
            background: #E10600;
        }
    }

    .right-side {
        margin-left: 20px;
    }

    .country {
        font-family: 'LeagueSpartan-Bold';
        font-size: 28px;
        line-height: 36px;
        text-transform: uppercase;
    }

    .city {
        font-family: 'Halcyon-Bold';
        font-size: 22px;
        line-height: 18px;
        margin-top: 5px;
        text-transform: uppercase;
    }

    .race-info {
        color: #38383F;
        display: flex;
        font-family: 'Halcyon-Regular';
        font-size: 10px;
        justify-content: space-between;
        max-width: 41%;
        width: 100%;

        .flex-content {
            align-items: flex-end;
            margin-bottom: 13px;
        }

        span {
            display: block;
            font-size: 8px;
        }

        b {
            color: #15151E;
            display: block;
            font-family: 'LeagueSpartan-Bold';
            font-weight: normal;
            font-size: 22px;
            line-height: 34px;
        }

        i {
            color: #15151E;
            font-family: 'Halcyon-Regular';
            font-style: normal;
            font-size: 10px;
        }
    }

    .map {
        width: 147px;
    }

    .strategy {
        align-items: center;
        background: transparent;
        border: 1px solid #C1C1C3;
        border-radius: 4px;
        color: #C1C1C3;
        cursor: pointer;
        display: flex;
        font-family: 'Halcyon-Regular';
        font-size: 12px;
        height: 35px;
        justify-content: center;
        line-height: 12px;
        margin-top: 20px;
        pointer-events: none;
        text-decoration: none;
        text-transform: uppercase;
        transition: all 550ms ease-in-out;
        width: 147px;

        &#active {
            border: 1px solid #E10600;
            color: #E10600;
            pointer-events: initial;
        }

        &:hover {
            background: #E10600;
            color: #FFFFFF !important;
        }
    }

    .race-results, .gplk-link {
        align-items: center;
        background: #E10600;
        border: 1px solid #E10600;
        border-radius: 4px;
        color: #FFFFFF;
        cursor: pointer;
        display: flex;
        font-family: 'Halcyon-Regular';
        font-size: 12px;
        height: 35px;
        justify-content: center;
        line-height: 12px;
        margin-top: 20px;
        text-decoration: none;
        text-transform: uppercase;
        transition: all 550ms ease-in-out;
        width: 147px;

        &:hover {
            background: transparent;
            color: #E10600;
        }
    }

    .race-results {
        position: relative;
        z-index: 1;
    }

    #race-1 { 
        background: url('../imgs/pista-01.jpg');
    }

    #race-2 { 
        background: url('../imgs/pista-02.jpg');
    }

    #race-3 { 
        background: url('../imgs/pista-03.jpg');
    }

    #race-4 { 
        background: url('../imgs/pista-04.jpg');
    }

    #race-5 { 
        background: url('../imgs/pista-05.jpg');
    }

    #race-6 { 
        background: url('../imgs/pista-06.jpg');
    }

    #race-7 { 
        background: url('../imgs/pista-07.jpg');
    }

    #race-8 { 
        background: url('../imgs/pista-08.jpg');
    }

    #race-9 { 
        background: url('../imgs/pista-09.jpg');
    }

    #race-10 { 
        background: url('../imgs/pista-10.jpg');
    }

    #race-11 { 
        background: url('../imgs/pista-11.jpg');
    }

    #race-12 { 
        background: url('../imgs/pista-12.jpg');
    }

    #race-13 { 
        background: url('../imgs/pista-13.jpg');
    }

    #race-14 { 
        background: url('../imgs/pista-14.jpg');
    }

    #race-15 { 
        background: url('../imgs/pista-15.jpg');
    }

    #race-16 { 
        background: url('../imgs/pista-16.jpg');
    }

    #race-17 { 
        background: url('../imgs/pista-17.jpg');
    }

    #race-18 { 
        background: url('../imgs/pista-18.jpg');
    }

    #race-19 { 
        background: url('../imgs/pista-19.jpg');
    }

    #race-20 { 
        background: url('../imgs/pista-20.jpg');
    }

    #race-21 { 
        background: url('../imgs/pista-21.jpg');
    }

    #race-22 { 
        background: url('../imgs/pista-22.jpg');
    }
}

@media (max-width: 1250px) {

    .block.races-list {

        .country {
            font-size: 24px;
            line-height: 32px;
        }

        .race-info {
            flex-direction: column;
        }

        .race-map {
            margin-top: 25px;
        }
    }
}

@media (max-width: 1120px) {

    .block.races-list {

        .race-date {
            padding: 30px 10px;
        }

        .country {
            font-size: 18px;
            line-height: 26px;
        }

        .city {
            font-size: 14px;
        }

        .right-side {
            margin-left: 10px;
        }
    }
}

@media (max-width: 767px) {

    .block.races-list {
        max-width: 100%;
        padding: 0 5px 164px;
        position: relative;

        &:after {
            background-image: linear-gradient(180deg, rgba(255,255,255,0.00) 1%, #FFFFFF 100%);
            bottom: 55px;
            content: '';
            display: block;
            height: 109px;
            left: 0;
            position: fixed;
            width: 100%;
        }

        .holder {
            max-width: 600px;
        }

        .content {
            margin-top: 10px;
        }

        .race {
            padding: 20px 0;
        }

        .race-date {
            max-width: 100%;
            min-height: 150px;
            padding: 20px;
        }

        .schedule {
            background: transparent !important;
            border: none !important;
            font-size: 12px;
            height: auto;
            line-height: 12ox;
            margin-top: 35px;
            width: fit-content;

            &#active {
                border: none;
            }
        }

        .strategy-mob {
            bottom: 0;
            color: #FFFFFF;
            font-family: 'Halcyon-Regular';
            font-size: 12px;
            line-height: 12px;
            opacity: 0.29;
            padding-bottom: 3px;
            pointer-events: none;
            position: absolute;
            right: 0;
            text-decoration: none;
            text-transform: uppercase;

            &:after {
                background: #C1C1C3;
                bottom: 0;
                content: '';
                display: block;
                height: 2px;
                left: 0;
                position: absolute;
                width: 100%;
            }

            &#active {
                opacity: 1;
                pointer-events: initial;

                &:after {
                    background: #E10600;
                }
            }
        }

        .race-results-mob {
            bottom: 0;
            color: #FFFFFF;
            font-family: 'Halcyon-Regular';
            font-size: 12px;
            line-height: 12px;
            padding-bottom: 3px;
            position: absolute;
            right: 0;
            text-decoration: none;
            text-transform: uppercase;

            &:after {
                background: #E10600;
                bottom: 0;
                content: '';
                display: block;
                height: 2px;
                left: 0;
                position: absolute;
                width: 100%;
            }
        }

        .index {
            font-size: 24px;
        }

        .flag {
            margin-left: 15px;
            max-width: 36px;
        }

        .date {
            font-size: 16px;
        }

        .country {
            font-size: 24px;
            line-height: 36px;
        }

        .city {
            font-size: 18px;
            line-height: 18px;
            margin-top: 3px;
        }
    
        .back {
            align-items: center;
            bottom: 100px;
            display: flex !important;
            left: 50%;
            transform: translateX(-50%);
            position: absolute;
            text-decoration: none;
            z-index: 1;
    
            .icon {
                max-width: 25px;
                width: 100%;
            }
    
            .text {
                color: #15151E;
                font-size: 16px;
                line-height: 23px;
                margin-left: 15px;
                text-transform: uppercase;
            }
        }
    }
}