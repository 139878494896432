.block.competitions {

    .competitors-holder {

        .competitors-list {
            margin: 30px auto 0;
            max-width: 270px;
            width: 100%;

            &.main {
                margin: 0 auto;
            }
        }

        .main {
            height: 334px;
            overflow-y: scroll;

            li {
                margin-bottom: unset !important;
            }
        }

        .col-left, .col-right {
            display: flex;
        }

        b {
            color: #F3F3F3;
            font-family: 'Halcyon-SemiBold';
            font-size: 16px;
        }

        li, .li {
            color: #F3F3F3;
            display: flex;
            font-family: 'Halcyon-Regular';
            font-size: 11px;
            justify-content: space-between;
            line-height: 18px;
            text-decoration: none;
            width: 100%;

            &:first-child {
                margin-bottom: 8px;
            }

            &.active {
                font-family: 'Halcyon-Bold';
            }
        }

        .name {
            margin-left: 15px;
        }

        .name.active {
            font-family: 'Halcyon-Bold';
        }

        .register {
            align-items: center;
            background: #E10600;
            border: 1px solid #E10600;
            border-radius: 4px;
            color: #FFFFFF;
            display: flex;
            font-family: 'Halcyon-Regular';
            font-size: 14px;
            height: 35px;
            justify-content: center;
            line-height: 18px;
            margin: 29px auto 0;
            text-transform: uppercase;
            transition: all 550ms ease-in-out;
            width: 130px;

            &:hover {
                background: transparent;
            }

            &#hidden {
                display: none !important;
            }
        }
    }
}

@media (max-height: 900px) {

    .block.competitions {

        .competitors-list {
            height: 232px;
            overflow-y: scroll;
            padding-right: 20px;
        }
    }
}

@media (max-width: 767px) {

    .block.competitions {

        .competitors-list {
            height: auto;
            overflow-y: unset;
            padding-right: 0;
        }
    }
}