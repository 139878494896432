.block.overall-table {
    padding: 150px 0;
    position: relative;

    &:after {
        background-image: linear-gradient(180deg, rgba(255,255,255,0.00) 1%, #ffffff 100%);
        bottom: 0;
        content: '';
        display: block;
        height: 150px;
        left: 0;
        position: fixed;
        width: 100%;
    }

    .holder {
        margin: 0 auto;
        max-width: 1107px;
        width: 100%;
    }

    .title {
        color: #E10600;
        font-family: 'LeagueSpartan-Bold';
        font-size: 28px;
    }

    .desc {
        color: #15151E;
        font-family: 'LeagueSpartan-Bold';
        font-size: 20px;
        text-transform: uppercase;
    }

    .content {
        margin-top: 40px;
    }

    .gplk-t-head {
        background: #E10600;
        color: #FFFFFF;
        font-family: 'LeagueSpartan-Bold';
        font-size: 14px;
        padding: 9px;
        text-align: center;
        text-transform: uppercase;
    }

    .gplk-t-row {
        display: flex;
        justify-content: space-between;
    }

    .main-row {

        .col {
            background: #38383F;
            color: #FFFFFF;
            font-family: 'Halcyon-Regular';
            font-size: 14px;
            padding: 6px 0 10px;
            text-align: center;
        }
    }

    .general-row {
        color: #15151E;
        font-family: 'Halcyon-Regular';
        font-size: 14px;
        margin-top: 5px;
        text-align: center;

        &#active {
            color: #E10600;
            font-family: 'Halcyon-SemiBold';
            font-size: 16px;
        }

        .col {
            padding: 10px 0 5px;
        }

        &:nth-child(even) {

            .col {
                background: #E9E9E9;
            }
        }

        &:nth-child(odd) {

            .col {
                background: #F5F5F5;
            }
        }
    }

    .col-1 {
        max-width: 12%;
        width: 100%;
    }

    .col-2 {
        max-width: 47%;
        width: 100%;
    }

    .col-3 {
        max-width: 20%;
        width: 100%;
    }

    .col-4 {
        max-width: 20%;
        width: 100%;
    }
    
    .back {
        align-items: center;
        bottom: 30px;
        display: flex;
        left: 70px;
        position: fixed;
        text-decoration: none;
        z-index: 1;

        .icon {
            max-width: 25px;
            width: 100%;
        }

        .text {
            color: #15151E;
            font-size: 16px;
            line-height: 23px;
            margin-left: 15px;
            text-transform: uppercase;
        }
    }
}

@media (max-width: 767px) {

    .block.overall-table {
        padding: 120px 25px 110px;

        &:after {
            height: 110px;
        }

        .main-row {

            .col {
                font-size: 12px;
                padding: 3px 0 4px;
                text-transform: uppercase;
            }
        }

        .back {
            bottom: 21px;
            left: 50%;
            transform: translateX(-50%);

            .icon {
                max-width: 20px;
            }

            .text {
                font-size: 14px;
                line-height: 15px;
                margin-left: 10px;
            }
        }
    }
}