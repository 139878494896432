.block.competitions {
    height: 100vh;
    padding: 0;

    .holder {
        margin-left: auto;
        max-width: 95%;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
    }

    .carrousel {
        position: relative;
    }

    .slick-slide {
        margin-right: 35px;
        max-width: 410px;
        width: 100%;
    }

    .item {
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        width: 100%;
    }

    .background {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;

        &:after {
            background-image: linear-gradient(180deg, rgba(0,0,0,0.20) 0%, #000000 100%);
            content: '';
            display: block;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }

    .item-holder {
        min-height: 628px;
        padding: 162px 25px 25px;
        position: relative;
    }

    .list-holder {
        min-height: 628px;
        padding: 35px 25px 25px;
        position: relative;
    }

    .title {
        color: #E10600;
        font-family: 'LeagueSpartan-Bold';
        font-size: 28px;
    }

    .desc {
        color: #ffffff;
        font-family: 'LeagueSpartan-Bold';
        font-size: 20px;
        text-transform: uppercase;
    }

    .credits {
        color: #FFFFFF;
        font-family: 'Halcyon-Bold';
        font-size: 35px;
        line-height: 40px;
        margin-top: 5px;
    }

    .item-content {
        margin-top: 15px;
    }

    .cupo, .bolsa {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        width: 300px;

        span {
            color: #F3F3F3;
            display: block;

            &:nth-child(1) {
                font-family: 'Halcyon-Regular';
                font-size: 16px;
                text-align: right;
                width: 110px;
            }

            &:nth-child(2) {
                font-family: 'Halcyon-Bold';
                font-size: 16px;
                width: 163px;
            }
        }
    }

    .prizes-content {
        margin-top: 20px;
    }

    .prizes-title {
        color: #F3F3F3;
        font-family: 'Halcyon-Regular';
        font-size: 16px;
        text-align: center;
        text-transform: uppercase;
    }

    ul {
        margin-top: 7px;

        span {
            display: block;
        }
    }

    li {
        color: #F3F3F3;
        display: flex;
        margin: 0 auto;
        width: 167px;
    }

    .position {
        font-family: 'Halcyon-Regular';
        font-size: 16px;
        line-height: 30px;
        text-align: right;
        width: 65px;
    }

    .percentage {
        font-family: 'Halcyon-Bold';
        font-size: 16px;
        line-height: 30px;
        padding: 0 10px 0 20px;
    }

    .icon {
        max-width: 15px;
    }

    .gplk-btn {
        align-items: center;
        background: #E10600;
        border: 1px solid #E10600;
        border-radius: 4px;
        color: #FFFFFF;
        display: flex;
        font-family: 'Halcyon-Regular';
        font-size: 14px;
        height: 35px;
        justify-content: center;
        line-height: 18px;
        margin: 17px auto 0;
        text-decoration: none;
        text-transform: uppercase;
        transition: all 550ms ease-in-out;
        width: 130px;

        &:hover {
            background: transparent;
        }
    }

    .credits-btn {
        align-items: center;
        background: #E10600;
        border: 1px solid #E10600;
        border-radius: 4px;
        color: #FFFFFF;
        display: flex;
        font-family: 'Halcyon-Regular';
        font-size: 14px;
        height: 35px;
        justify-content: center;
        line-height: 18px;
        margin: 40px auto 0;
        outline: none;
        text-decoration: none;
        text-transform: uppercase;
        transition: all 550ms ease-in-out;
        width: 180px;

        &:hover {
            background: transparent;
            color: #E10600;
        }
    }

    .slick-prev, .slick-next {
        border: none;
        bottom: -74px;
        color: inherit;
        height: 25px;
        position: absolute;
        top: unset;transform: none;
        width: 25px;

        &:before {
            content: none;
        }
    }

    .slick-prev {
        background: url('../imgs/arrow-l-1.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        left: 0;
    }

    .slick-next {
        background: url('../imgs/arrow-r-1.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        right: 70px;
    }
}

@media (max-height: 900px) {

    .block.competitions {

        .slick-slide {
            max-width: 350px;
        }

        .item-holder {
            padding: 25px;
        }

        .list-holder, .item-holder {
            min-height: 446px;
        }

        .credits-btn {
            margin: 10px auto 0;
        }

        .slick-prev, .slick-next {
            bottom: -35px;
        }
    }
}

@media (max-width: 767px) {

    .block.competitions {
        height: auto;
        padding: 120px 25px 116px;

        .holder {
            max-width: 100%;
            top: unset;
            transform: none;
        }

        .carrousel {
            flex-direction: column;
            width: 100%;
        }

        .item {
            margin: 0 auto 30px;
            max-width: 600px;
            padding: 20px;
            width: 100%;
        }

        .item-holder {
            padding: 0;
        }
    }
}