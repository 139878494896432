.pl-modal-races {
    align-items: center;
    background: #F3F3F3;
    border-radius: 10px;
    display: flex;
    height: 436px;
    justify-content: center;
    margin: 0 auto;
    max-width: 632px;
    outline: none;
    overflow: hidden;
    position: relative;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;

    .close-modal {
        cursor: pointer;
        height: 22px;
        position: absolute;
        right: 15px;
        top: 15px;
        width: 22px;
        z-index: 1;

        img {
            left: 0;
            position: absolute;
            top: 0;
            transition: all 550ms ease-in-out;
        }

        .icon-normal {
            opacity: 1;
        }

        .icon-hover {
            opacity: 0;
        }

        &:hover {

            .icon-hover {
                opacity: 1;
            }

            .icon-normal {
                opacity: 0;
            }
        }
    }

    .holder {
        margin: 0 auto;
        max-width: 410px;
        position: relative;
        width: 100%;
    }

    .header {
        font-family: 'Halcyon-Bold';
        line-height: 19px;
        text-align: center;
        text-transform: uppercase;
    }

    .title {
        color: #E10600;
        font-size: 22px;
    }

    .desc {
        color: #38383F;
        font-size: 18px;
    }

    .content {
        margin: 25px auto 0;
        max-width: 270px;
        width: 100%;
    }

    .flex-content {
        color: #15151E;
        display: flex;
        font-family: 'Halcyon-Regular';
        font-size: 16px;
        justify-content: space-between;
        line-height: 17px;
        text-transform: uppercase;

        span {
            font-size: 10px;
        }

        b {
            font-family: 'Halcyon-Bold';
        }
    }

    .right {
        text-align: left;
        width: 100px;
    }

    .advertising {
        align-items: center;
        background: #E10600;
        border-radius: 10px;
        color: #FFFFFF;
        display: flex;
        font-family: 'Halcyon-Bold';
        font-size: 16px;
        height: 65px;
        justify-content: center;
        line-height: 19px;
        margin: 20px auto 15px;
        padding: 0 20px;
        text-align: center;
        width: 257px;
    }

    .accept {
        align-items: center;
        border: 1px solid #E10600;
        border-radius: 4px;
        color: #15151E;
        cursor: pointer;
        display: flex;
        font-family: 'Halcyon-Regular';
        font-size: 14px;
        height: 40px;
        justify-content: center;
        line-height: 18px;
        margin: 30px auto 0;
        text-transform: uppercase;
        transition: all 550ms ease-in-out;
        width: 150px;

        &:hover {
            background: #E10600;
            color: #FFFFFF;
        }
    }
}

@media (max-width: 767px) {

    .pl-modal-races {
        width: 95%;
    }
}