.block.login {
    height: 100vh;
    position: relative;

    &:before {
        background: url('../imgs/brw.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        display: block;
        height: 55px;
        left: 50%;
        position: absolute;
        top: 38px;
        transform: translateX(-50%);
        width: 160px;
        z-index: 1;
    }

    .background {
        background: url('../imgs/login-back.webp');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;

        &:after {
            background: #FFFFFF;
            content: '';
            display: block;
            height: 100%;
            left: 0;
            opacity: .8;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }

    .holder {
        margin: 0 auto;
        max-width: 440px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }

    .title {
        color: #15151E;
        font-family: 'LeagueSpartan-Bold';
        font-size: 48px;
        text-align: center;
    }

    .content {
        padding-top: 15px;
    }

    .input-container {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 20px;
    }

    label {
        color: #15151F;
        display: block;
        font-family: 'Halcyon-Regular';
        font-size: 14px;
        font-weight: normal;
        margin-bottom: 6px;
    }

    input[type=text], input[type=email], input[type=password] {
        background: transparent;
        border: 1px solid rgba(56, 56, 63, .5);
        border-radius: 2px;
        box-shadow: none;
        color: #15151F;
        font-family: 'Halcyon-Regular';
        font-size: 16px;
        height: auto;
        outline: none !important;
        padding: 11px 14px;
        transition: all 550ms ease;
        width: 100%;

        &:focus {
            border: 1px solid #151520;

            +label {
                font-family: 'Halcyon-SemiBold';
                font-weight: bold;
            }
        }
    }

    .input-group-append {
        height: 100%;
        position: absolute;
        right: 0;
        width: 42px;
        z-index: 122222;
    }

    #show_password, #show_confirm_password {
        background: transparent;
        border: none;
        box-shadow: none !important;
        outline: none;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
    }

    .fa-custom {
        color: #383840;
    }

    button.submit {
        align-items: center;
        background: #E10600;
        border: none;
        border-radius: 4px;
        color: #FFFFFF;
        display: flex;
        font-family: 'Halcyon-Regular';
        font-size: 14px;
        height: 40px;
        justify-content: center;
        line-height: 18px;
        margin: 35px auto 0;
        text-transform: uppercase;
        transition: all 550ms ease-in-out;
        width: 186px;

        &:hover {
            background: #740300;
        }
    }

    a.forgot-password {
        color: #38383F;
        display: block;
        font-family: 'Halcyon-Regular';
        font-size: 12px;
        line-height: 18px;
        margin-top: 15px;
        text-align: center;
        text-decoration: none;
    }

    a.password-succeed {
        color: #38383F;
        display: block;
        font-family: 'Halcyon-Regular';
        font-size: 30px;
        line-height: 36px;
        margin-top: 50px;
        text-align: center;
        text-decoration: none;
    }
}

@media (max-height: 600px) {

    .block.login {

        &:before {
            height: 35px;
            top: 18px;
            width: 101px;
        }

        .title {
            font-size: 28px;
            line-height: 42px;
        }

        .content {
            padding-top: unset;
        }
    }
}

@media (max-width: 767px) {

    .block.login {

        &:before {
            height: 35px;
            top: 18px;
            width: 101px;
        }

        .holder {
            max-width: 364px;
        }

        .title {
            font-size: 28px;
            line-height: 42px;
        }

        .content {
            padding-top: 20px;
        }

        label {
            font-size: 12px;
            margin-bottom: 2px;
        }

        button.submit {
            font-size: 12px;
            height: 35px;
            line-height: 12px;
            margin: 40px auto 0;
            width: 120px;
        }

        a.forgot-password {
            font-size: 11px;
            line-height: 15px;
            margin-top: 20px;
        }
    }
}