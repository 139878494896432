.block.register {
    height: 100vh;
    position: relative;

    &:before {
        background: url('../imgs/brw.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        display: block;
        height: 55px;
        left: 50%;
        position: absolute;
        top: 38px;
        transform: translateX(-50%);
        width: 160px;
        z-index: 1;
    }

    .background {
        background: url('../imgs/registro-back.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .holder {
        margin: 0 auto;
        max-width: 1180px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }

    .header {
        max-width: 366px;
        width: 100%;
    }

    .title {
        color: #15151E;
        font-family: 'LeagueSpartan-Bold';
        font-size: 48px;
        position: relative;

        &:after {
            background: #E10600;
            bottom: 0;
            content: '';
            display: block;
            height: 3px;
            left: -33px;
            position: absolute;
            width: 67px;
        }
    }

    .desc {
        font-family: 'Halcyon-Regular';
        font-size: 16px;
        line-height: 25px;
        margin-top: 25px;

        b {
            display: block;
            font-family: 'Halcyon-Bold';
            font-size: 18px;
        }
    }

    .content {
        margin-top: 30px;
    }

    .flex-content {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    .image-wrapper {
        max-width: 31.8%;
        width: 100%;
    }

    .image {
        border-radius: 8px;
        overflow: hidden;
    }

    .form-wrapper {
        max-width: 64.6%;
        width: 100%;
    }

    .flex-input {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;

        &:first-child {
            margin-top: unset;
        }
    }

    .input-container-small {
        max-width: 40.2%;
        width: 100%;
    }

    .input-container-large {
        max-width: 55.2%;
        width: 100%;
    }

    .input-container {
        display: flex;
        flex-direction: column-reverse;
    }

    label {
        color: #15151F;
        display: block;
        font-family: Halcyon-Regular;
        font-size: 14px;
        font-weight: normal;
        margin-bottom: 6px;
    }

    input[type=text], input[type=email], input[type=password] {
        background: transparent;
        border: 1px solid rgba(56, 56, 63, .5);
        border-radius: 2px;
        box-shadow: none;
        color: #15151F;
        font-family: 'Halcyon-Regular';
        font-size: 16px;
        height: auto;
        outline: none !important;
        padding: 11px 14px;
        transition: all 550ms ease;
        width: 100%;

        &:focus {
            border: 1px solid #151520;

            +label {
                font-family: 'Halcyon-SemiBold';
            }
        }
    }

    .input-group-append {
        height: 100%;
        position: absolute;
        right: 0;
        width: 42px;
        z-index: 122222;
    }

    #show_password, #show_confirm_password {
        background: transparent;
        border: none;
        box-shadow: none !important;
        outline: none;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
    }

    .fa-custom {
        color: #383840;
    }

    .flex-input-btns {
        display: flex;
        justify-content: space-between;
        margin-left: auto;
        margin-top: 50px;
        max-width: 295px;
        width: 100%;
    
        a, .register {
            align-items: center;
            display: flex;
            height: 40px;
            font-family: 'Halcyon-Regular';
            font-size: 14px;
            justify-content: center;
            line-height: 18px;
            text-decoration: none;
            text-transform: uppercase;
            transition: all 550ms ease-in-out;
            width: 130px;
        }
    }
    
    a.cancel {
        background: transparent;
        border: 2px solid #E10600;
        border-radius: 4px;
        color: #38383F;
    
        &:hover {
            background: #E10600;
            color: #FFFFFF;
        }
    }
    
    .register {
        background: #E10600;
        border: none;
        border-radius: 4px;
        color: #FFFFFF;
    
        &:hover {
            background: #740300;
        }
    }
}

@media (max-height: 800px) {

    .block.register {
        height: auto;
        padding: 130px 15px;

        .holder {
            top: unset;
            transform: none;
        }
    }
}

@media (max-width: 767px) {

    .block.register {
        height: auto;
        padding: 120px 25px 150px;

        &:before {
            height: 35px;
            top: 18px;
            width: 101px;
        }

        .holder {
            top: unset;
            transform: none;
        }

        .title {
            font-size: 28px;
            line-height: 42px;
            padding-bottom: 2px;

            &:after {
                height: 2px;
                left: -25px;
                width: 50px;
            }
        }

        .desc {
            font-size: 14px;
            line-height: 18px;
            margin-top: 20px;

            b {
                font-size: 16px;
            }
        }

        .content {
            margin-top: 0;
            padding-top: 20px;
        }

        .form-wrapper {
            max-width: 100%;
        }

        .flex-input {
            flex-wrap: wrap;
            margin-top: 0;
        }

        .input-container-large, .input-container-small {
            max-width: 100%;
        }

        .input-container {
            margin-top: 20px;
        }

        label {
            font-size: 12px;
            line-height: 18px;
            margin-bottom: 2px;
        }

        input[type=text], input[type=password] {
            font-size: 14px;
            padding: 7px 8px;
        }

        .flex-input-btns {
            margin-right: auto;
            margin-top: 30px;
            max-width: 270px;
        
            a, .register {
                font-size: 12px;
                height: 35px;
                line-height: 12px;
                width: 120px;
            }
        }
    }
}