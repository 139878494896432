.pl-modal {

    .header {
        line-height: 19px;
    }

    .title {
        font-family: 'Halcyon-Bold';
        font-size: 22px;
    }

    .desc {
        font-family: 'Halcyon-Bold';
        font-size: 18px;
    }

    .flex-content {
        display: flex;
        justify-content: space-between;
        margin-top: 25px;

        div {
            max-width: 45%;
            width: 100%;

            &:first-child {
                text-align: right;
            }

            &:last-child {
                text-align: left;
            }
        }
    }

    .btn-continue {
        align-items: center;
        background: #E10600;
        border-radius: 4px;
        color: #FFFFFF;
        cursor: pointer;
        display: flex;
        font-family: 'Halcyon-Regular';
        font-size: 14px;
        height: 40px;
        justify-content: center;
        letter-spacing: 0;
        line-height: 18px;
        margin: 0 auto;
        margin-top: 40px;
        text-decoration: none;
        width: 150px;
    }

    .btn-cancel {
        color: #38383F;
        cursor: pointer;
        font-family: 'Halcyon-Regular';
        font-size: 12px;
        letter-spacing: 0;
        line-height: 18px;
        margin-top: 15px;
        text-align: center;
    }
}